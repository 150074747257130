import Player from "@vimeo/player";

export function heroVimeoBg(video, isPlayingOnStartup) {

  let player = null;
  const width = video.width || 16;
  const height = video.height || 9;
  const ratio = video.height / video.width;
  const inverseRatio = video.width / video.height;

  return {
    playing : false,
    ready : false,

    iframeStyle: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: width,
      height: height,
      opacity: video.opacity || 1.0,
    },

    init() {
      const md = window.matchMedia('(min-width: 768px)')
      if (! md.matches) {
        console.log('do not play videos on mobile');
        return;
      }

      if (isPlayingOnStartup) {
        this.play();
      }

      // `activeVideo` is coming from the parent component
      // it would be nice if this was done in twig
      this.$watch('activeVideo', (v) => {
        if(v === video.id) {
          this.play();
        } else {
          this.pause();
        }
      });
    },

    play() {
      if(player && ! this.playing) {
        player.setCurrentTime(0);
        player.play();
        return;
      }

      const $el = this.$refs.handstick;
      if(!$el || ! video.vimeoId) return;

      console.log(`Initializing video ${video.title}...`);

      player = new Player($el, {
        id: video.vimeoId,
        autoplay: true,
        background: true,
        muted: true,
        responsive: false,
        loop: true
      });

      player.on('loaded', () => {
        console.log(`Video ${video.title} is ready.`);
        this.ready = true;
        this.resize();
        window.addEventListener('resize', this.resize.bind(this), false);
      });

      player.on('playing', () => {
        console.log(`Video ${video.title} is playing.`);
        this.playing = true;
      });

      player.on('pause', () => {
        console.log(`Video ${video.title} is paused.`);
        this.playing = false;
      });
    },

    pause() {
      if ( ! player || ! this.playing ) return;
      player.pause();
    },

    resize() {
      const container = this.$refs.container;
      const w = container.offsetWidth;
      const h = container.offsetHeight;
      const windowRatio = h / w;

      if (windowRatio > ratio) {
        this.iframeStyle.width = `${h * inverseRatio}px`;
        this.iframeStyle.height = `${h}px`;
      } else {
        this.iframeStyle.width = `${w}px`;
        this.iframeStyle.height = `${w * ratio}px`;
      }
    },
  };
}
