import Swiper from 'swiper';
import { Autoplay, EffectFade, Manipulation } from 'swiper/modules';

/**
 * Hero Slideshow component.
 *
 * @param opts
 * @returns {{init(): void, swiper: null}}
 */
export function heroSlider(opts = {}) {

    let swiper = null;
    let swiperEl = null;
    let autoplayDelay = 2000;
    let autoTransitionSpeed = 1000;
    let lazySlides = null;

    return {

        pauseTimer: null,
        resumeTimer: null,

        init() {
            lazySlides = Array.from(this.$root.getElementsByTagName('template'));
            swiperEl = this.$refs.swiperEl || this.$root;
            swiper = new Swiper(swiperEl, {
                speed: autoTransitionSpeed,
                autoplay: {
                    delay: autoplayDelay,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                },
                effect: 'fade',
                crossFade: true,
                loop: true,
                slidesPerView: 1,
                modules: [Autoplay, EffectFade, Manipulation],
                on: {
                    afterInit: function() {

                        // Delay autoplay start on first slide
                        this.autoplay.stop();
                        setTimeout(() => { this.autoplay.start(); }, autoplayDelay);

                        if(lazySlides.length) {
                            let tmpl = lazySlides.shift();
                            this.appendSlide(tmpl.content);
                        }
                    },
                    slideChangeTransitionEnd: function() {
                        if(lazySlides.length) {
                            let tmpl = lazySlides.shift();
                            this.appendSlide(tmpl.content);
                        }
                    }
                }
            });
        },
    };
}
