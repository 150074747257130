export function heroVideos(videos) {

  return {
    videos: videos,
    activeVideo: null,

    init() {
      if(! this.videos.length) {
        console.log('aborting because no videos passed');
        return;
      }

      this.activeVideo = this.videos[0].id;
    },

    get currentVideo() {
      const found = this.videos.filter( ({ id }) => id === this.activeVideo );
      return found && found.length ? found[0] : null;
    },

    get currentVideoIndex() {
      return this.videos.findIndex((v) => v.id === this.activeVideo);
    },

    next() {
      const current = this.currentVideoIndex;
      let nextVideoIndex = current + 1;
      if (nextVideoIndex == this.videos.length) {
        nextVideoIndex = 0;
      }

      this.activeVideo = this.videos[nextVideoIndex]['id'];
    },

    previous() {
      const current = this.currentVideoIndex;
      let previousVideoIndex = current - 1;

      if (previousVideoIndex < 0) {
        previousVideoIndex = this.videos.length - 1;
      }

      this.activeVideo = this.videos[previousVideoIndex]['id'];
    },

    hover(id) {
      if ( !id ) {
        return;
      }
      this.activeVideo = id;
    },
  };
}
