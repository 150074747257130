import Alpine from "alpinejs";

import { embedAssetResizer } from './embedAssetResizer.js';
import { heroImages } from "./hero-images.js";
import { heroSlider } from "./swiper.js";
import { heroVideos } from "./hero-videos.js";
import { heroVimeoBg } from "./hero-vimeo-bg";
import { mapbox } from './mapbox.js';

Alpine.data("embedAssetResizer", embedAssetResizer);
Alpine.data("heroImages", heroImages);
Alpine.data("heroSlider", heroSlider);
Alpine.data("heroVideos", heroVideos);
Alpine.data("heroVimeoBg", heroVimeoBg);
Alpine.data("mapbox", mapbox);

/* start your engines */
window.Alpine = Alpine;
Alpine.start();
