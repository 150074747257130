export function heroImages(videos) {
  return {
    get currentVideo() {
      const found = this.videos.filter( ({ id }) => id === this.activeVideo );
      if ( found && found.length ) {
        return found[0];
      }

      return false;
    },


  };
}
