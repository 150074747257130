/**
 * Refresh if our app version has changed
 */
const getVersionFromHtml = function(html) {
  try {
    const meta = html.querySelector('meta[name="X-App-Version"]');
    return meta.getAttribute('content');
  } catch(e) {
    console.log('app:getVersionFromHtml', e.message);
    return null;
  }
};

const isLoadPageSafe = function ({ url, layer }) {
  // To prevent any overlays from closing, we only make a full page load
  // when the link is changing the root layer.
  return url && up.layer.current.isRoot() && layer !== 'new';
};

const currentVersion = getVersionFromHtml(document);
console.log('app:currentVersion', currentVersion);

if(currentVersion) {
  // reload if new build version detected
  up.on('up:fragment:loaded', (event) => {

    if( ! isLoadPageSafe(event.renderOptions)) {
      console.log('do not reload an overlay');
      return;
    }

    try {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(event.response.text, 'text/html');
      const responseVersion = getVersionFromHtml(parsedHtml);
      console.log('app:responseVersion', responseVersion);
      if(responseVersion && responseVersion !== currentVersion) {
        throw new Error('New version detected: ' + responseVersion + ' !== ' + currentVersion);
      }
    } catch(e) {
      console.log('app:versionMismatch', e.message);
      up.cache.expire();
      event.preventDefault();
      return event.request.loadPage();
    };
  });
}

/**
 * @TODO device detection to limit scope?
 * Safari bug doesn't size srcset when loaded over ajax
 * https://www.debugcn.com/en/article/16843265.html
 * Also has an issue with video!
 */
up.on('up:fragment:inserted', (event, fragment) => {
  const els = fragment.querySelectorAll('img[srcset], video');
  if( ! els) {
    return;
  }
  els.forEach(function(el) {
    el.outerHTML = el.outerHTML;
  });
});

/**
 * Smooth scroll to target?
 */
up.on('up:fragment:inserted', function(event, fragment) {
  if(fragment.hasAttribute('data-scroll-to-target')) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        fragment.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top
    });
  }
});
