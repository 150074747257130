/* CSS */
import '@js/parts/css';

/* JS */
import '@js/parts/flickity';
import '@js/parts/lazyloading';
import '@js/parts/simplebar';
import '@js/parts/unpoly';
import 'what-input';
import { initHeader } from "@js/parts/sticky-header";
initHeader();

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import "@js/parts/alpinejs/index";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
